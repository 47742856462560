import React from 'react';
import './styles';
import Menu from './common/Menu/Menu';
import Home from './modules/Home/Home';
import Book from './modules/Book/Book';
import About from './modules/About/About';

function App() {
  return (
    <>
      <div className="fixbg"></div>
      <div className="col-xs-12 col-md-10 col-md-offset-1 wrapper">
        <Menu />
        <div className="main-section">
          <section id="main">
            <div>
              <Home />
              <Book />
              <About />
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default App;
