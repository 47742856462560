import React from 'react';
import './book.css';

export default function Book() {
  return (
    <div>
      <div id="book" className="col-xs-12">
        <div className="cont col-xs-12 col-md-10 col-md-offset-1">
          <h1 className="title">The Book</h1>
          <div className="item open">
            <p className="itemDesc col-md-12 col-xs-12">
              A strong academic foundation – how his father’s efforts helped him build it during his school years,  how it helped him get admission in the University of Roorkee and IIM, Ahmedabad,  how it helped him bounce back after what the author calls  a ‘lean period’ in his life and how he is determined to help primary school children of the village Chanchli  build a strong academic foundation which will help them become self-learners as they navigate school after primary school, through his free school Shiksha Vikas Vidyalaya <a href="www.svvindia.org" target="_blank">(www.svvindia.org)</a> - that is the theme running through Vinay Singh’s first book.
            </p>
            <p className="itemDesc col-md-12 col-xs-12">
              Although the title of the book refers to a pattern of training used in mountaineering to help climbers’ bodies acclimatize to rarefied atmosphere at high altitude, the appeal of the book is not limited to mountaineers.  The world of mountain-climbing may be far removed from the world of formal education but, with Vinay’s experience in both fields, this book is his attempt to explain similarities in practice of one concept in two disparate worlds. Following the principle of work high, sleep low, mountaineers acclimatize by climbing higher than their campsite and then descending to spend the night. This can be equated to going further after the initial goal has been achieved. A similar concept has application in the field of education and is referred to by psychologists as “overlearning” - the practice of rehearsing a task beyond the point when initial proficiency has been achieved.
                </p>
            <p className="itemDesc col-md-12 col-xs-12">
              According to Vinay, his father’s approach to learning which can be equated with overlearning, helped him build a strong academic foundation. Told with humorous reflections and hard-earned insight as Vinay takes the reader on a journey through his childhood and a career that begins in India and continues in the United States, the book is certain to keep the reader engaged.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}