import React, { useState } from 'react';
import Modal from '../../common/Modal/Modal';
import BookCover from '../../images/potrait.jpg';
import msKohli from '../../images/mskohli.jpg';
import './home.css';

export default function Home() {
  const [showVideo, setShowVideo] = useState(true);
  const [showPic, setShowPic] = useState(false);

  return (
    <section id="home">
      <div className="slide row">
        <img src={BookCover} className="card book-cover col-xs-12 col-sm-5 col-sm-offset-1" style={{ "opacity": 0 }} alt='' />
        <div className="quote card col-xs-12 col-sm-4 col-sm-offset-1" style={{ "opacity": 0 }} >
          <a href="Foreword.pdf" className="foreword" target="blank">
            "...........a masterpiece of good writing, an excellent commentary on contemporary happenings and a remarkable guide for the young people to move up in life."
          </a>
          <br />
          <strong onClick={() => setShowPic(true)}>~ Capt. M.S. Kohli</strong>
        </div>
        <div
          className="quote card col-xs-12 col-sm-4 col-sm-offset-1"
          style={{ opacity: 0, marginTop: "100px" }}
          onClick={() => setShowVideo(true)}
        >
          <iframe title="youtube" width="350" height="200" src="https://www.youtube.com/embed/xMWxlJKc9KQ" frameBorder="0" style={{ pointerEvents: "none" }} allowFullScreen></iframe>
        </div>
      </div>
      {showVideo && (
        <Modal onClose={() => setShowVideo(false)}>
          <iframe
            title='video'
            width="853"
            height="480"
            src="https://www.youtube.com/embed/xMWxlJKc9KQ?rel=0&showinfo=0&autoplay=1"
            frameBorder="0"
            allowFullScreen>
          </iframe>
        </Modal>
      )}
      {showPic && (
        <Modal onClose={() => setShowPic(false)}>
          <img className='msKohli' src={msKohli} alt='ms kohli' />
        </Modal>
      )}
    </section >
  )
}