import React from 'react';
import './about.css';
import DP from '../../images/dp2.jpg';

export default function About() {
  return (
    <div id="about" className="about col-xs-12">
      <div className="cont col-xs-12 col-md-10 col-md-offset-1">
        <h1 className="author-name">Vinay Singh</h1>
        <div className="row">
          <div className="col-sm-12">
            <img className="col-xs-12 col-sm-4 author-pic img-responsive" src={DP} alt='dp' />
            <p className="content">
              An engineer and MBA, Vinay Singh is a 53-year-old entrepreneur who has a passion for mountain climbing and has founded an innovative school in his ancestral village to provide free education. He currently lives in Maryland, USA with his wife and two daughters.
                    </p>
            <p className="content">
              Vinay’s life has not followed the script his formal education would seem to have ensured. However, instead of allowing his life to spin out of control and become a helpless spectator, Vinay chose to create a meaningful path, and as husband, father, entrepreneur, and educator he has turned difficult eventualities into opportunity and growth. A proclivity to choose action over passivity, combined with his father’s lifelong emphasis on education, prompted Vinay to build his school, Shiksha Vikas Vidyalaya, which opened in 2012. He can be reached at vinay@workhighsleeplow.com
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}