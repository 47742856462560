import React from 'react';
import './menu.css';

const menuList = [
  {
    name: 'Home',
    link: '/',
    hash: 'home',
  },
  {
    name: 'Book',
    link: '/book',
    hash: 'book',
  },
  {
    name: 'About',
    link: '/about',
    hash: 'about',
  }
]

function Menu() {
  return (
    <header>
      <div className="header-cont">
        <div className="logo-cont">
          <div className="brand-name">
            <h1 className="book-name">Work High Sleep Low </h1>
            <h2 className="author-name">
              <span style={{ fontStyle: "italic", wordSpacing: "9px", fontSize: "smaller" }}>by </span> Vinay Singh
          </h2>
          </div>
        </div>
        <div className="menu-cont">
          <div className="menu-bar">
            {
              menuList.map(menu => (
                <a className="menu-item" key={menu.name} href={`#${menu.hash}`}>{menu.name}</a>
              ))
            }
            <a className="menu-item" href="http://www.svvindia.org" target='_blank' rel="noopener noreferrer">School</a>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Menu;
