import React, { useCallback, useEffect } from 'react';
import './modal.css';

function noop() { }

export default function Modal(props) {
  const { onClose = noop } = props;

  const handleKey = useCallback(e => {
    if (e.keyCode === 27) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    document.addEventListener('keydown', handleKey, true);
    return () => {
      document.removeEventListener('keydown', handleKey);
    }
  }, [handleKey]);

  return (
    <div className="modalContainer" onClick={onClose}>
      <div className="modalContent" onClick={(e) => e.stopPropagation()}>
        {props.children}
      </div>
    </div>
  )
}